.App {
  text-align: center;
}

form {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  font-size: 28px;
  border: none;
  border-bottom: 1px solid #ededed;
  background-color: #121212;
  width: 500px;
  color: #ededed;
}

input:focus-visible {
  outline: none;
}

button {
  background-color: #ff4c29;
  font-size: 20px;
  color: #ededed;
  border: none;
  border-radius: 3px;
  padding: 5px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}
